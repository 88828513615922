<template>
  <div class="container-fluid">
    <div class="container landing-info">
      <div class="details-button--exit landInfo">
        <a @click="$router.go(-1)" class="button-exit--img"></a>
        <a @click="$router.go(-1)">{{$t('catalogDetails.exit')}}</a>
      </div>
      <div class="landing-info--text">
        <h2 class="h2">{{$t('land-info.h1')}}</h2>
        <span>
          {{$t('land-info.text-1')}}
          <br>
          <br>
          {{$t('land-info.text-2')}}
          <br>
          <br>
          {{$t('land-info.text-3')}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpoLandingInfo"
}
</script>

<style scoped>

</style>
